import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import "yup-phone";
import styles from "./entryform.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import format from "date-fns/format";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  Alert,
  Button,
  createTheme,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { Info } from "./Info";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { addYears, isAfter, isBefore } from "date-fns";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { registrationHandler } from "../../utils/registrationHandler";

export function EntryForm() {
  const MIN_DATE = addYears(new Date(), -99);
  const MAX_DATE = addYears(new Date(), -18);
  const [dateNotValid, setDateNotValid] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [dateTouched, setDateTouched] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [emptyDate, setEmptyDate] = useState(false);
  const [values, setValues] = React.useState({});
  const [errors, setErrors] = useState(false);
  const [errMsg, setErrMsg] = useState("Unknown error, try again");
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    username: yup.string().required("Username is required"),
    gender: yup.string().required("Gender is required"),
    lookingGender: yup.string().required("Gender is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password required"),
  });

  useEffect(() => {
    console.log(1);
    setTimeout(() => {
      setErrors(false);
      setErrMsg("Unknown error, try again");
      console.log(2);
    }, 3000);
  }, [errors]);

  const validateDate = (dateValue) => {
    if (!dateValue) {
      setEmptyDate(true);
      setDateNotValid(true);
      return;
    }
    const enteredYear = parseInt(dateValue.toString().split(" ")[3], 10);
    if (enteredYear) {
      setEmptyDate(false);
      if (1900 < enteredYear && enteredYear < 2050) {
        setYearError(false);
      } else {
        setDateNotValid(true);
        setYearError(true);
        return;
      }
    }

    const currentDate = new Date(dateValue.toString());
    if (isAfter(currentDate, MIN_DATE) && isBefore(currentDate, MAX_DATE)) {
      setEmptyDate(false);
      setDateNotValid(false);
      setYearError(false);
    } else {
      setEmptyDate(false);
      setDateNotValid(true);
      return;
    }
    setEmptyDate(false);
    setDateNotValid(false);
    setYearError(false);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      gender: "",
      lookingGender: "",
      //city: "",
      password: "",
      showPassword: false,
      dob: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (dateNotValid || yearError || emptyDate) {
        return;
      }
      values.dob = format(new Date(dateValue.toString()), "yyyy-LL-dd");
      try {
        setLoading(true);
        const regResult = await registrationHandler(values);
        if (regResult.success === 0) {
          setErrors(true);
          if (regResult.message) {
            setErrMsg(regResult.message);
          }
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const theme = createTheme({
    palette: {
      neutral: {
        main: "#4f5257",
        contrastText: "#fff",
      },
    },
  });

  const handleClickShowPassword = () => {
    setValues((formik.values.showPassword = !formik.values.showPassword));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <FormControl sx={{ mb: 0, minWidth: 1 }}>
          <i className={styles.formDescription}>
            You perfect match in just a click away!
          </i>
          <Stack spacing={0} sx={{ mb: 1, width: 1 }}>
            <TextField
              sx={{ width: 1, mb: 2 }}
              className={styles.formField}
              id="gender"
              name="gender"
              select
              label="I'am a"
              SelectProps={{
                IconComponent: (props) => <ExpandMoreIcon {...props} />,
              }}
              value={formik.values.gender}
              onChange={formik.handleChange}
              helperText={formik.touched.gender && formik.errors.gender}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
            </TextField>

            <TextField
              id="lookingGender"
              name="lookingGender"
              className={styles.formField}
              sx={{ width: 1, mb: 2 }}
              SelectProps={{
                IconComponent: (props) => <ExpandMoreIcon {...props} />,
              }}
              select
              label="Looking for a"
              value={formik.values.lookingGender}
              onChange={formik.handleChange}
              helperText={
                formik.touched.lookingGender && formik.errors.lookingGender
              }
              error={
                formik.touched.lookingGender &&
                Boolean(formik.errors.lookingGender)
              }
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
              <MenuItem value={"Both"}>Both</MenuItem>
            </TextField>
          </Stack>
          <Stack direction={"row"} spacing={3} sx={{ mb: 2, width: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={{ width: 1 / 2 }}
                className={styles.formField}
                id="dob"
                name="dob"
                label="Date of birth"
                value={dateValue}
                inputFormat={"dd-MM-yyyy"}
                maxDate={dayjs(MAX_DATE)}
                minDate={dayjs(MIN_DATE)}
                views={["year", "month", "day"]}
                openTo={"year"}
                onChange={(newDateValue) => {
                  setDateValue(newDateValue);
                  setEmptyDate(false);
                  validateDate(newDateValue);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: 1 / 2 }}
                    className={styles.formField}
                    {...params}
                    error={
                      Boolean(dateNotValid && dateTouched) || Boolean(emptyDate)
                    }
                    helperText={
                      Boolean(dateNotValid && dateTouched) && (
                        <span>
                          {Boolean(!yearError && !emptyDate) && (
                            <span>
                              Enter valid date <br /> (you must be at least 18
                              years old)
                            </span>
                          )}
                          {Boolean(yearError) && (
                            <span>Enter valid Year (i.e. 1978)</span>
                          )}
                          {Boolean(emptyDate) && (
                            <span>Date of birth is required</span>
                          )}
                        </span>
                      )
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Stack direction={"row"} spacing={3} sx={{ mb: 4, width: 1 }}>
            <TextField
              sx={{ width: 1 / 2 }}
              id="username"
              className={styles.formField}
              name="username"
              label="Username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
            <FormControl sx={{ width: 1 / 2 }} className={styles.formField}>
              <InputLabel htmlFor="password">Password</InputLabel>

              <OutlinedInput
                id="password"
                name="password"
                type={formik.values.showPassword ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {formik.values.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              <FormHelperText
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
              >
                <>
                  {formik.touched.password &&
                    Boolean(formik.errors.password) && (
                      <span>{formik.errors.password}</span>
                    )}
                </>
              </FormHelperText>
            </FormControl>
          </Stack>
          <Stack direction={"row"} spacing={3} sx={{ mb: 2, width: 1 }}>
            <Info />
            <ThemeProvider theme={theme}>
              <LoadingButton
                loading={loading}
                variant="outlined"
                color="neutral"
                type="submit"
                sx={{ width: 1 / 2 }}
                onClick={() => {
                  setDateTouched(true);
                  validateDate(dateValue);
                }}
              >
                Submit
              </LoadingButton>
            </ThemeProvider>
          </Stack>
        </FormControl>
      </form>
      {Boolean(errors && errMsg) && <Alert severity="error">{errMsg}</Alert>}
    </div>
  );
}
