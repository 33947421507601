import React, { useRef, useState } from "react";
import styles from "./login.module.css";
import classNames from "classnames";
import { LoginForm } from "./LoginForm";
import { useClickAway } from "react-use";

export function LogIn() {
  const ref = useRef(null);

  const [open, setOpen] = useState(false);
  useClickAway(ref, () => {
    setOpen(false);
  });
  const handleClick = () => {
    setOpen(!open);
  };
  const style = classNames(styles.logIn, { [styles[`logInOpen`]]: open });
  return (
    <div ref={ref}>
      <div onClick={handleClick} className={style}>
        log in
      </div>
      {open && (
        <div>
          <LoginForm />
        </div>
      )}
    </div>
  );
}
